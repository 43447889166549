import { COLOR } from "../../Constants";

const heatMap = [
  "#c4c4c4",
  "#4676CA",
  "#5E87D1",
  "#7699D8",
  "#91ADE0",
  "#A9BFE7",
  "#C3D3EE",
  "#DCE5F5",
  "#F2F2F2",
  "#F7DAE2",
  "#F1C0CF",
  "#EBA5BA",
  "#E68BA6",
  "#E06F91",
  "#DA567D",
  "#D53D69"
];
export const WIDGET_CHART_COMMON_STYLE = {
  _insights_textStyle: {
    color: COLOR.mainGrey,
    greyColor: "#626262",
    fontSize: "0.78rem",
    fontFamily: "Inter"
  },
  _dashboard_textStyle: {
    color: COLOR.mainSubLight,
    greyColor: "#626262",
    fontSize: "0.78rem",
    fontFamily: "Inter"
  },
  _seriesSymbolStyle: {
    type: "line",
    symbol: "circle", // 设置小圆点的样式
    symbolSize: 6 // 设置小圆点的大小
  },
  _legend: {
    itemWidth: 8,
    itemHeight: 8,
    itemGap: 10,
    left: "right"
  },
  _barStyle: {
    color: ["#6ED2C8", "#A4D7E8", "#78AEDC", "#4B77BE", "#D75455", "#5CB85C", "#BD4B85", "#E89F3E"],
    lessDataColor: ["#6ED2C8", "#78AEDC"],
    barColorDarkArr: ["#D75455", "#E97D7D", "#5CB85C", "#79CC79", "#BD4B85", "#C96BA1", "#E89F3E", "#F5B75F"],
    heatmap: heatMap,
    group: [
      "#E97D7D",
      "#FFA500",
      "#5CB85C",
      "#BD4B85",
      "#78AEDC",
      "#A4D7E8",
      "#6ED2C8",
      "#8EE5DE",
      "#BFE3F3",
      "#95C4E9",
      "#6A94D4",
      "#F5B75F",
      "#C96BA1",
      "#79CC79",
      "#E97D7D",
      "#4B77BE",
      "#4B77BE",
      ...heatMap
    ],
    temperature: ["#D75455", "#5CB85C", "#BD4B85", "#E89F3E", "#4B77BE", "#78AEDC", "#6ED2C8"],
    group_1: [
      "#6ED2C8",
      "#A4D7E8",
      "#78AEDC",
      "#4B77BE",
      "#F5B75F",
      "#C96BA1",
      "#79CC79",
      "#E97D7D",
      "#8EE5DE",
      "#BFE3F3",
      "#95C4E9",
      "#6A94D4",
      "#E89F3E",
      "#BD4B85",
      "#5CB85C",
      "#D75455"
    ],
    breakDownEnergy: [
      "#E97D7D",
      "#79CC79",
      "#C96BA1",
      "#F5B75F",
      "#6A94D4",
      "#95C4E9",
      "#BFE3F3",
      "#8EE5DE",
      "#D75455",
      "#5CB85C",
      "#BD4B85",
      "#E89F3E",
      "#4B77BE",
      "#78AEDC",
      "#A4D7E8",
      "#E97D7D"
    ]
  },
  _grid: {
    width: "100%",
    left: "auto",
    right: "auto",
    top: "auto",
    bottom: "1%",
    height: "auto",
    containLabel: true
  },
  _gridIncludeLegend: {
    width: "auto",
    top: "15%",
    left: "2%",
    right: "0%",
    bottom: "1%",
    height: "auto",
    containLabel: true
  }
};
