import { COLOR } from "../../../Constants";
import moment from "moment";
import React from "react";
import { PAGE_PERIOD_LENGEND_TEXT, WIDGET_UNIT_BY_SOURCE_TYPE, UNIT_TO_COST_VALUE, UNIT_TO_SQUARE, UNIT_COST_TO_YAXIS_VALUE, UNIT_RATE_OF_SCHOOL_STUDENT } from "../common";
import { WIDGET_CHART_COMMON_STYLE } from "../commonOption";
export const DASHBOARD_DEFAULT_WIDGETS = [
  "ScoreCardWidget",
  "BarAndLineChartWithACTemperatureWidget",
  "BarChartHorizontalWithAirQualityWidget",
  "BarAndLineChartWithLightingOperateHourWidget",
  "ACRequestSummaryWidget",
  "BarChartHorizontalWithStackedWidget",
  "ACRequestCompareWidget",
  "WeatherWidget"
];
export const PAGE_LAYOUT_WIDGET_PARAMETER = {
  WIDGET_SOURCE_TYPE: {
    ENERGY: "Energy",
    WATER: "Water",
    TEMPERATURE: "Temperature"
  },
  UNIT_TO_COST: function (unit, value, flag) {
    let isEUI = false;
    switch (unit) {
      case "ENERGY":
      case "E_CO2":
      case "E_EUI":
        isEUI = unit.indexOf("EUI") != -1;
        return (
          <>
            {value ? ToRoundDecimal(value).toLocaleString() : null}
            {flag ? (
              <span className="ps-05">
                {WIDGET_UNIT_BY_SOURCE_TYPE[unit]}
                {isEUI ? <sup>2</sup> : null}
              </span>
            ) : null}
          </>
        );
        break;
      case "E_COST":
        return (
          <>
            {flag ? <span className="ps-05">{WIDGET_UNIT_BY_SOURCE_TYPE[unit]}</span> : null}
            {value ? ToRoundDecimal(value).toLocaleString() : null}
          </>
        );
        return;
        break;
      case "E_BURGER":
      case "E_TAXI":
      case "E_FOOT":
      case "E_TEA":
      case "E_RAMEN":
      case "E_BREAD":
      case "E_TREE":
        return (
          <>
            {flag ? <span className="ps-05">{WIDGET_UNIT_BY_SOURCE_TYPE[unit]}</span> : null}
            {value ? ToRoundDecimal(value * UNIT_RATE_OF_SCHOOL_STUDENT[unit]).toLocaleString() : null}
          </>
        );
        break;
      case "WATER":
      case "W_CO2":
      case "W_EUI":
        isEUI = unit.indexOf("EUI") != -1;
        return (
          <>
            {value ? ToRoundDecimal(value).toLocaleString() : null}
            {flag ? (
              <span className="ps-05">
                {WIDGET_UNIT_BY_SOURCE_TYPE[unit]}
                {isEUI ? <sup>2</sup> : null}
              </span>
            ) : null}
          </>
        );
        break;
      case "W_COST":
        return (
          <>
            {flag ? <span className="ps-05">{WIDGET_UNIT_BY_SOURCE_TYPE[unit]}</span> : null}
            {value ? ToRoundDecimal(value).toLocaleString() : null}
          </>
        );
        break;
      case "TEMPERATURE":
        return value ? ToRoundDecimalTemperature(value).toLocaleString() : "" + WIDGET_UNIT_BY_SOURCE_TYPE[unit];
        break;
      case "IAQ":
        return value ? ToRoundDecimalTemperature(value).toLocaleString() : "" + WIDGET_UNIT_BY_SOURCE_TYPE[unit];
        break;
      case "IAQ_TEMPERATURE":
        return value ? ToRoundDecimalTemperature(value).toLocaleString() : "" + WIDGET_UNIT_BY_SOURCE_TYPE[unit];
        break;
      case "IAQ_HUMIDITY":
        return value ? ToRoundDecimalTemperature(value).toLocaleString() : "" + WIDGET_UNIT_BY_SOURCE_TYPE[unit];
        break;
      case "IAQ_TVOC":
        return value ? ToRoundDecimalTemperature(value).toLocaleString() : "" + WIDGET_UNIT_BY_SOURCE_TYPE[unit];
        break;
      case "IAQ_CO2":
        return value ? ToRoundDecimalTemperature(value).toLocaleString() : "" + WIDGET_UNIT_BY_SOURCE_TYPE[unit];
        break;
      case "IAQ_PM25":
        return value ? ToRoundDecimalTemperature(value).toLocaleString() : "" + WIDGET_UNIT_BY_SOURCE_TYPE[unit];
        break;
    }
  },
  UNIT_METRIC: function (unit) {
    switch (unit) {
      case "E_ENERGY":
      case "ENERGY":
        return "ENERGY";
      case "W_WATER":
      case "WATER":
        return "WATER";
      case "E_COST":
      case "W_COST":
        return "COST";
      case "E_CO2":
      case "W_CO2":
        return "CO2";
      case "E_EUI":
      case "W_EUI":
        return "EUI";
      case "TEMPERATURE":
        return "TEMPERATURE";
      case "IAQ_TEMPERATURE":
        return "TEMPERATURE";
      case "IAQ_HUMIDITY":
        return "HUMIDITY";
      case "IAQ_TVOC":
        return "TVOC";
      case "IAQ_CO2":
        return "CO2";
      case "IAQ_PM25":
        return "PM25";
    }
  }
};

const WIDGET_CHART_STYLE = {
  _axis: {
    fontSize: "0.85rem"
  },
  _axisLineStyle: {
    color: "#ccc", // 刻度标线的颜色
    width: 0.5, // 刻度标线的宽度
    type: "solid" // 刻度标线的类型（实线、虚线等）
  }
};
const WIDGET_CHART_ACTION = {
  _unitFormatter: function (params, type, unit) {
    let value = UNIT_TO_COST_VALUE(unit, params.value, false);
    unit = WIDGET_UNIT_BY_SOURCE_TYPE[unit] + UNIT_TO_SQUARE(unit);
    return `{name|${value}}\n${unit}`;
  },
  _barInnerRich: {
    name: {
      fontSize: "1.07rem",
      fontFamily: "Inter"
    },
    unit: {
      fontSize: "0.92rem",
      fontFamily: "Inter",
      padding: [4, 0, 0, 0]
    },
    num: {
      fontSize: 8
    }
  }
};

export const BarChartWithMeterMulitColorOption = (CATEGORY, DATA, LENGEND_DATA, type, widget) => {
  let unit = WIDGET_UNIT_BY_SOURCE_TYPE[widget.unit];
  const series = [],
    legendData = [];
  if (!DATA?.length) {
    return {};
  }
  let MAX = 1;
  DATA.map((item, index) => {
    if (MAX < Math.max(...item.value)) {
      MAX = Math.max(...item.value);
    }
  });
  let tempMax = { max: 0 };
  DATA?.map((item, index) => {
    let max = Math.max(...item.value);
    if (tempMax.max < max) {
      tempMax.max = max;
    }
    let currentColor = [];
    if (DATA.length > 2) {
      currentColor = WIDGET_CHART_COMMON_STYLE._barStyle.barColorDarkArr[index % WIDGET_CHART_COMMON_STYLE._barStyle.barColorDarkArr.length];
    } else {
      if (index % 2 == 0) {
        currentColor = WIDGET_CHART_COMMON_STYLE._barStyle.lessDataColor[0];
      } else {
        currentColor = WIDGET_CHART_COMMON_STYLE._barStyle.lessDataColor[1];
      }
    }
    series.push({
      name: item?.name,
      type: "bar",
      barCategoryGap: "0%", // 设置类目轴上的柱子之间的间隔为0
      barWidth: (1 / DATA.length) * 100 - 10 + "%", // 可以根据需要设置柱子宽度
      barGap: "0.1", // 设置柱子之间的间隔为0
      tooltip: {
        valueFormatter: function (value) {
          return ToDecimal(value) + item?.unit;
        }
      },
      itemStyle: {
        normal: {
          color: function (params) {
            return currentColor;
          },
          borderRadius: [3, 3, 0, 0]
        }
      },
      label: {
        show: false,
        position: "inside", // 自定义标签的位置，[x, y]
        distance: 10,
        formatter: function (params) {},
        textStyle: {
          ...WIDGET_CHART_COMMON_STYLE._insights_textStyle
        }
      },
      data: item?.value
    });
    legendData.push({
      name: item?.name,
      icon: "rect",
      textStyle: {
        color: currentColor, // 设置图例1的文字颜色为蓝色
        fontSize: "0.85rem"
      },
      itemStyle: { color: currentColor }
    });
  });
  return {
    tooltip: {
      trigger: "axis",
      confine: true,
      z: 9999,
      formatter: function (params) {
        let html = "",
          name = "";
        params.forEach(item => {
          name = item.name + "<br/>";
          let result = UNIT_TO_COST_VALUE(widget?.unit, item.value, true);
          html += `${item.marker}${item?.seriesName}：<span class="bold">${result}</span><br/>`;
        });
        return name + html;
      }
    },
    grid: {
      ...WIDGET_CHART_COMMON_STYLE._gridIncludeLegend,
      left: "2%",
      containLabel: true,
      splitLine: {
        show: true,
        lineStyle: {
          type: "solid"
        }
      }
    },
    legend: {
      data: legendData,
      ...WIDGET_CHART_COMMON_STYLE["_legend"],
      top: "8%",
      formatter: function (name) {
        return "{a|" + name + "}";
      },
      textStyle: {
        ...WIDGET_CHART_COMMON_STYLE._insights_textStyle,
        letterSpacing: 10,
        rich: {
          a: {
            width: "auto", // 设置宽度自适应
            fontSize: "12px"
          }
        }
      }
    },
    xAxis: [
      {
        type: "category",
        data: CATEGORY,
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: COLOR.mainSubLight, // 标线的颜色
            width: 1, // 标线的宽度
            type: "solid" // 标线的类型（实线、虚线等）
          }
        },
        axisLabel: {
          show: true,
          interval: 0,
          textStyle: {
            ...WIDGET_CHART_COMMON_STYLE._insights_textStyle
          },
          formatter: function (value) {
            return value;
          },
          tooltip: {
            show: true
          }
        }
      }
    ],
    yAxis: [
      {
        type: "value",
        name: unit + UNIT_TO_SQUARE(widget?.unit),
        min: 0,
        MAX: parseInt(MAX),
        nameTextStyle: { color: COLOR.mainGrey, fontSize: "0.78rem", fontFamily: "Inter", align: "center" },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false,
          lineStyle: {
            color: COLOR.mainSubLight
          }
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: COLOR.mainSubLight, // 标线的颜色
            width: 1, // 标线的宽度
            type: "solid" // 标线的类型（实线、虚线等）
          }
        },
        axisLabel: {
          show: true,
          textStyle: {
            ...WIDGET_CHART_COMMON_STYLE._insights_textStyle
          },
          formatter: function (value) {
            return UNIT_COST_TO_YAXIS_VALUE(widget.unit, value);
          }
        }
      }
    ],
    series: [...series]
  };
};
//LineChartWithGreenWidget - option
export const LineChartWidgetOption = (category, list, type, width, widget, t) => {
  if (!category.length || !list.length) {
    return {};
  }
  let unit = WIDGET_UNIT_BY_SOURCE_TYPE[widget?.unit];
  let seriesData = [],
    legendData = [],
    tempLengendData = [];

  list?.forEach((item, index) => {
    seriesData.push({
      data: item?.data,
      name: item?.name + "_" + index,
      path: item?.path,
      lineStyle: {
        color: WIDGET_CHART_COMMON_STYLE._barStyle.group[index], // 标线的颜色
        width: 2, // 标线的宽度
        type: "solid" // 标线的类型（实线、虚线等）
      },
      itemStyle: item.isCompare
        ? {
            normal: {
              color: "#FFA500", // 设置实心点的颜色
              borderColor: COLOR.main, // 设置边框颜色
              borderWidth: 2 // 设置边框宽度
            }
          }
        : {
            normal: {
              color: WIDGET_CHART_COMMON_STYLE._barStyle.group[index], // 设置实心点的颜色
              borderColor: "transparent", // 设置边框颜色
              borderWidth: 2 // 设置边框宽度
            }
          },
      ...WIDGET_CHART_COMMON_STYLE._seriesSymbolStyle
    });
    legendData.push({
      name: item?.name + "_" + index,
      icon: "rect",
      textStyle: {
        color: WIDGET_CHART_COMMON_STYLE._barStyle.group[index], // 设置图例1的文字颜色为蓝色
        fontSize: "12px"
      },
      itemStyle: { borderColor: "transparent" }
    });
    tempLengendData.push(item?.name);
  });
  if (widget.referenceData) {
    legendData.push({ name: widget.referenceData.name, icon: "rect", textStyle: { color: COLOR.blue }, itemStyle: { color: COLOR.blue, borderColor: "transparent" } });
    seriesData.push({
      name: widget.referenceData.name,
      type: "line",
      symbol: "none",
      cursor: "auto",
      yAxisIndex: 1,
      smooth: true,
      lineStyle: {
        width: 2,
        type: "solid", // 设置为虚线
        color: COLOR.blue
      },
      data: widget.referenceData.data
    });
  }
  const yAxis = getLineBarChartYAxis(widget);
  return {
    legend: getLineBarChartLegend(legendData),
    tooltip: {
      formatter: function (params) {
        let val = 0;
        val = UNIT_TO_COST_VALUE(widget?.unit, params.value, true);
        if (widget.previousSingleParams) {
          let result = null;
          if (widget.previousSingleParams.pellet == "PELLET_HOUR") {
            result = moment(params.data.ts * 1000)
              .format("MM-DD h:a")
              .split(":");
            result = result[0] + "" + result[1] + " ";
          } else {
            result = moment(params.data.ts * 1000).format("MM-DD") + " ";
          }
          return `${params.marker}${params?.data?.path || list[params.seriesIndex]?.name || ""}<br/>${result}<span class="bold">${val}</span>`;
        } else {
          return `${params.marker}${params?.data?.path || list[params.seriesIndex]?.name || ""}<br/>${getTimeFormatByPelletOrViewby(
            widget,
            params.name,
            true
          )}<span class="bold">${val}</span>`;
        }
      }
    },
    xAxis: {
      type: "category",
      data: category,
      splitLine: {
        show: false
      },
      axisTick: {
        show: false // 显示 X 轴的刻度标线
      },
      axisLine: {
        show: true,
        lineStyle: {
          ...WIDGET_CHART_STYLE._axisLineStyle,
          color: COLOR.mainSubLight // 标线的颜色
        }
      },
      axisLabel: {
        show: true,
        interval: widget.viewBy == "hour" && TimestampConvertPellet(widget.startTime, widget.endTime).pellet.indexOf("DAY") != -1 ? 0 : null,
        formatter: function (value, index) {
          if (widget.previousSingleParams) {
            if (widget.previousSingleParams.pellet == "PELLET_HOUR") {
              let params = moment(value * 1000)
                .format("h:a")
                .split(":");
              return params[0] + "" + params[1];
            } else {
              return moment(value * 1000).format("MM-DD") + " ";
            }
          } else {
            if (widget.viewBy == "hour") {
              if (TimestampConvertPellet(widget.startTime, widget.endTime).pellet.indexOf("DAY") != -1) {
                let result = getTimeFormatByPelletOrViewby(widget, value, false, "D/M");
                if (index % 24 == 0) {
                  return result;
                } else {
                  return "";
                }
                return getTimeFormatByPelletOrViewby(widget, value, false, "D/M");
              }
            }
            return getTimeFormatByPelletOrViewby(widget, value, false);
          }
        },
        textStyle: {
          ...WIDGET_CHART_COMMON_STYLE._insights_textStyle,
          color: COLOR.mainGrey,
          fontSize: "0.80rem"
        }
      }
    },
    grid: getLineBarChartGrid(widget),
    yAxis: widget.referenceData
      ? [
          yAxis,
          {
            ...yAxis,
            name: t("lbl.degree"),
            axisLabel: {
              show: true,
              textStyle: {
                color: COLOR.mainGrey,
                fontSize: "0.8rem",
                fontFamily: "Inter"
              }
            }
          }
        ]
      : yAxis,
    series: seriesData
  };
};
//PieChartConsumptionWidget - option
export const PieChartWidgetOption = (listObj, widget) => {
  if (!listObj?.list?.length) {
    return {};
  }
  return {
    grid: {
      left: "-5%"
    },
    title: {
      text: UNIT_TO_COST_VALUE(widget.unit, listObj?.total || 0, true),
      textStyle: {
        color: COLOR.mainSubLight,
        fontSize: "0.92rem",
        fontFamily: "Inter"
      },
      subtext: "Used",
      subtextStyle: {
        color: COLOR.mainSubLight,
        fontSize: "1.07rem",
        fontFamily: "Inter"
      },
      itemGap: 5,
      top: "center",
      left: "center"
    },
    tooltip: {
      formatter: function (params) {
        const {
          data: { originalValue, path }
        } = params;
        return `${params.marker}${path || params?.name}：${params.value}%(${UNIT_TO_COST_VALUE(widget.unit, originalValue, true)})`;
      }
    },
    series: [
      {
        type: "pie",
        radius: ["45%", "60%"],
        center: ["50%", "50%"],
        clockwise: true,
        avoidLabelOverlap: true,
        hoverAnimation: false,
        itemStyle: {
          normal: {
            color: function (params) {
              const group = WIDGET_CHART_COMMON_STYLE._barStyle.group;
              return group[params.dataIndex % group.length];
            }
          }
        },
        label: {
          show: true,
          position: "outer",
          formatter: "{icon|''}{name|{b}}\n{value|{c}%}",
          rich: {
            icon: {
              width: 10,
              height: 10,
              backgroundColor: "inherit",
              color: "inherit",
              fontFamily: "Inter"
            },
            name: {
              fontSize: 11,
              fontWeight: "normal",
              padding: [0, 10, 0, 4],
              color: COLOR.mainSubLight
            },
            value: {
              fontSize: "0.78rem",
              fontWeight: "normal",
              padding: [2, 0, 0, 15],
              color: COLOR.mainSubLight,
              fontFamily: "Inter",
              align: "left"
            }
          }
        },
        labelLine: {
          normal: {
            length: 10,
            length2: 10,
            lineStyle: {
              width: 0,
              color: "#CDCDCD"
            }
          }
        },
        data: listObj?.list || []
      }
    ]
  };
};
function splitArrayIntoGroupsExcludingZero(array) {
  // 过滤出为0的元素并放入一个单独的数组
  const zeros = array.filter(item => item === 0);
  // 过滤掉为0的元素
  const nonZeroArray = array.filter(item => item !== 0);
  // 计算每组应有的元素数量（向上取整）
  const groupSize = Math.ceil(nonZeroArray.length / 15);
  // 分组非零元素
  const groups = [];
  for (let i = 0; i < nonZeroArray.length; i += groupSize) {
    const end = Math.min(i + groupSize, nonZeroArray.length);
    const group = nonZeroArray.slice(i, end);
    groups.push(group);
  }
  // 返回非零分组和零的数组
  return {
    groups: groups,
    zeros: zeros
  };
}

export const HeatMapWidgetOption = (categoryX, categoryY, list, type, widget) => {
  const data = list[0]?.data || [];
  const targetLength = categoryY.length;
  const week = [" S", " M", " T", " W", " T", " F", " S"];
  if (!data.length || !categoryX.length || !categoryY.length) {
    return {};
  }
  let valueArr = [];
  data?.map(item => {
    valueArr.push(item[item.length - 1]);
  });
  valueArr.sort((a, b) => a - b);
  const groupedArray = splitArrayIntoGroupsExcludingZero(valueArr);
  const { groups, zeros } = groupedArray;
  groups.unshift(zeros);
  let pieces = [];
  for (let i = 0; i < groups.length; i++) {
    if (i == 0) {
      pieces.push({ min: 0, max: 0, color: WIDGET_CHART_COMMON_STYLE._barStyle.heatmap[i] });
    } else {
      pieces.push({
        min: Math.min(...groups[i]),
        max: Math.max(...groups[i]),
        gte: Math.min(...groups[i]),
        lte: Math.max(...groups[i]),
        color: WIDGET_CHART_COMMON_STYLE._barStyle.heatmap[i]
      });
    }
  }
  //根据当前数组长度自动补齐数组
  while (categoryY.length < targetLength) {
    categoryY.push("");
  }
  return {
    tooltip: {
      formatter: function (params) {
        const _DATE = categoryY[params.value[1]].split("/");
        const dayOffWeek = new Date("20" + JSON.parse(JSON.stringify(_DATE)).reverse().join("-")).getDay();
        if (categoryY[params.value[1]].startsWith("more")) {
          return null;
        }
        let time,
          value = 0;
        if (params.value[0] <= 11) {
          time = params.value[0] + "\n" + "am";
        } else {
          time = (params.value[0] == 12 ? 12 : params.value[0] % 12) + "\n" + "pm";
        }
        if (params?.value?.[2]) {
          value = UNIT_TO_COST_VALUE(widget?.unit, params.value[2], true);
        }
        return `${week[dayOffWeek] || ""} ${categoryY[params.value[1]]}<br/>${params.marker}${time}：<span class="bold">${value}</span>`;
      }
    },
    grid: {
      ...WIDGET_CHART_COMMON_STYLE._gridIncludeLegend,
      right: categoryY?.length > 14 ? "25" : "0%",
      top: "5%",
      splitLine: {
        show: true,
        lineStyle: {
          type: "solid"
        }
      }
    },
    xAxis: {
      type: "category",
      data: categoryX,
      axisTick: { show: false },
      axisLine: { show: false },
      splitArea: { show: false },
      axisLabel: {
        show: true,
        interval: 1,
        textStyle: {
          ...WIDGET_CHART_COMMON_STYLE._insights_textStyle,
          color: COLOR.mainGrey,
          fontSize: "0.8rem",
          fontFamily: "Inter"
        },
        formatter: function (value) {
          if (value <= 11) {
            return value + "am";
          } else {
            return (value == 12 ? 12 : value % 12) + "pm";
          }
        }
      }
    },
    yAxis: {
      type: "category",
      data: categoryY,
      interval: 0,
      axisTick: { show: false },
      axisLine: { show: false },
      splitArea: { show: false },
      axisLabel: {
        clickable: true, // 启用y轴label的点击事件
        show: true,
        formatter: function (date) {
          if (date && date != "more") {
            const _DATE = date.split("-");
            const dayOffWeek = new Date(JSON.parse(JSON.stringify(_DATE)).join("-")).getDay();
            return "{date|" + week[dayOffWeek] + parseInt(_DATE[2]) + "/" + parseInt(_DATE[1]) + "}";
          } else if (date && date == "more") {
            return date + "..";
          } else {
            return null;
          }
        },
        textStyle: {
          rich: {
            date: {
              color: COLOR.mainGrey,
              fontSize: "0.8rem",
              fontFamily: "Inter"
            },
            week: {
              color: COLOR.mainGrey,
              fontSize: "0.8rem",
              fontFamily: "Inter"
            }
          }
        }
      },
      triggerEvent: true
    },
    visualMap: {
      //需要可展开
      show: false,
      type: "piecewise",
      min: valueArr?.length ? Math.min(...valueArr) : 0, // 数据值的最小值
      max: valueArr?.length ? Math.max(...valueArr) : 0, // 数据值的最大值
      calculable: true, // 是否显示拖拽用的手柄
      pieces: pieces
    },
    dataZoom:
      categoryY?.length <= 14
        ? []
        : [
            {
              show: true,
              type: "slider",
              yAxisIndex: [0, 1],
              filterMode: "empty",
              width: 16,
              right: "0%",
              start: 100,
              end: 50,
              handleIcon: "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
              handleSize: "110%",
              moveHandleSize: 7,
              handleStyle: {
                color: "rgba(77,100,141,.7)",
                borderColor: "transparent",
                borderRadius: 0
              },
              textStyle: {
                color: COLOR.main,
                fontSize: 12,
                fontFamily: "Inter"
              },
              labelPreacision: "auto",
              labelFormatter: "",
              showDetail: true,
              emphasis: {
                handleStyle: {
                  borderColor: "transparent"
                },
                moveHandleStyle: {
                  borderColor: "transparent"
                }
              },
              borderRadius: 0,
              showDataShadow: false,
              brushSelect: false,
              borderJoin: "miter"
            }
          ],
    series: [
      {
        name: "HeatMap",
        type: "heatmap",
        data: data,
        splitArea: {
          show: false
        },
        label: {
          show: false
        },
        pointSize: 14,
        symbolSize: [13.75, 24.14], // 设置每个点的宽高
        itemStyle: {
          borderColor: COLOR.white,
          borderWidth: 2,
          borderRadius: 0
        },
        axisLabel: {
          interval: 3 // 设置刻度标签显示间隔
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 2,
            shadowColor: "#4d648d"
          }
        }
      }
    ]
  };
};
export const BarChartEnergyBreakDownOption = (category = "", list = [], type) => {
  if (!list?.length) {
    return {};
  }
  let obj_reconstitution = {};
  list.map(pItem => {
    pItem.data?.map(cItem => {
      if (obj_reconstitution[cItem.name]) {
        obj_reconstitution[cItem.name] = {
          name: cItem.name,
          data: [...obj_reconstitution[cItem.name].data, cItem.value]
        };
      } else {
        obj_reconstitution[cItem.name] = {
          name: cItem.name,
          data: [cItem.value]
        };
      }
    });
  });
  const data_reconstitution = Object.keys(obj_reconstitution).map(key => obj_reconstitution[key]);
  const categoryX = Object.keys(obj_reconstitution);
  const categoryY = category;
  return BarChartWaterBreakDownOption(categoryX, categoryY, [{ data: data_reconstitution }], type);
};
export const BarChartWaterBreakDownOption = (categoryX, categoryY, list, type) => {
  if (!list[0]?.data?.length) {
    return {};
  }
  let color = null;
  if (type == "WET_WATER") {
    color = WIDGET_CHART_COMMON_STYLE._barStyle.group_1;
  } else {
    color = WIDGET_CHART_COMMON_STYLE._barStyle.breakDownEnergy;
  }
  let legendData = [];
  let seriesData = [];
  categoryX.map((item, index) => {
    legendData.push({
      name: item,
      icon: "rect",
      textStyle: {
        color: color[index],
        fontSize: WIDGET_CHART_STYLE._axis.fontSize,
        fontFamily: "Inter"
      }
    });
  });
  let max = [];
  list[0]?.data.map((item, index) => {
    let currentData = [];
    item.data.forEach((item, index) => {
      currentData.push(item);
      if (max[index]) {
        max[index] += parseFloat(currentData[index]);
      } else {
        max[index] = parseFloat(currentData[index]);
      }
    });
    seriesData.push({
      name: item.name,
      type: "bar",
      stack: "Tik Tok",
      itemStyle: {
        color: color[index],
        shadowColor: color[index],
        shadowBlur: 0,
        shadowOffsetY: 0,
        shadowOffsetX: 0,
        borderWidth: 1
      },
      label: {
        normal: {
          show: true,
          position: "inside",
          formatter: function (params) {
            if (params.value < 10) {
              return "";
            }
            return params.value + "%";
          },
          textStyle: {
            align: "center",
            baseline: "middle",
            fontSize: 14,
            fontWeight: "400",
            color: COLOR.white,
            fontFamily: "Inter"
          }
        }
      },
      data: currentData
    });
  });
  return {
    legend: {
      show: true,
      data: [...legendData],
      ...WIDGET_CHART_COMMON_STYLE["_legend"],
      itemGap: 5,
      left: 5,
      bottom: 1,
      formatter: function (name) {
        return "{a|" + name + "}";
      },
      textStyle: {
        ...WIDGET_CHART_COMMON_STYLE._insights_textStyle,
        letterSpacing: 10,
        rich: {
          a: {
            width: "auto", // 设置宽度自适应
            fontSize: "0.71rem",
            color: COLOR.mainSubLight,
            padding: [3, 0, 0, 4]
          }
        }
      }
    },
    tooltip: {
      formatter: function (params) {
        if (categoryX.includes(params.seriesName)) {
          return `${params.marker}${params.seriesName}：${params.value}%`;
        } else {
          return null;
        }
      }
    },
    grid: {
      ...WIDGET_CHART_COMMON_STYLE._grid,
      left: "-1%",
      right: "auto",
      top: list[0].data.length == 1 ? "0%" : list[0].data.length > 5 ? "-10%" : "-10%",
      height: list[0].data.length == 1 ? "150%" : list[0].data.length > 5 ? "120%" : "140%"
    },
    xAxis: [
      {
        show: false
      },
      {
        show: false
      }
    ],
    yAxis: {
      type: "category",
      inverse: true,
      show: false
    },
    series: [
      {
        name: "",
        type: "bar",
        xAxisIndex: 0, //使用的 x 轴的 index，在单个图表实例中存在多个 x 轴的时候有用。
        yAxisIndex: 0, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用。
        data: [...max, 100],
        barWidth: 5,
        itemStyle: {
          normal: {
            color: "transparent"
          }
        },
        z: 1
      },
      ...seriesData,
      {
        show: false,
        type: "bar",
        xAxisIndex: 1, //代表使用第二个X轴刻度
        barGap: "-100%",
        barWidth: "10%",
        itemStyle: {
          normal: {
            barBorderRadius: 200,
            color: "transparent"
          }
        },
        label: {
          normal: {
            show: true,
            position: "leftTop",
            padding: [-20, 0, 0, 0],
            textStyle: {
              fontSize: "0.78rem",
              fontFamily: "Inter",
              color: COLOR.main,
              fontWeight: "bold"
            },
            formatter: function (data) {
              return categoryY[data.dataIndex];
            }
          }
        },
        data: max
      }
    ]
  };
};
function setAllFridgeStyleObj(points, color) {
  let fridgetObj = {};
  for (let index in points) {
    index = parseInt(index) + 1;
    fridgetObj = {
      ...fridgetObj,
      ["fridge" + index]: {
        width: "auto",
        color: COLOR.white,
        backgroundColor: color[index - 1],
        borderRadius: 2,
        padding: [2, 5, 2, 5],
        fontFamily: "Inter"
      }
    };
  }
  return fridgetObj;
}
function maxLength(twoDArray) {
  return twoDArray.reduce((max, current) => {
    return Math.max(max, current.length);
  }, 0);
}

export const ScatterChartTemperatureDistributionOption = (categoryX, data, pointsFridge, width, widget) => {
  if (data?.length === 0) {
    return {};
  }
  let { default_set_point = 4 } = widget?.parameters ? JSON.parse(widget?.parameters) : {};
  let color = WIDGET_CHART_COMMON_STYLE._barStyle.temperature;
  let tempMax = null,
    tempMin = null,
    seriesData = []; // 计算中间值
  let regroupObj = {};
  let max = maxLength(data);
  data = data.map(item => {
    if (item?.length < max) {
      for (let i = 0; i < max; i++) {
        item.push({});
      }
    }
    return item;
  });
  data.map(item => {
    item?.map((cItem, index) => {
      if (regroupObj[index] && item[index]) {
        if (!tempMax) tempMax = cItem.value;
        if (!tempMin) tempMin = cItem.value;
        if (cItem.value > tempMax) {
          tempMax = cItem.value;
        }
        if (cItem.value < tempMin) {
          if (cItem.value) {
            tempMin = cItem.value;
          }
        }
        regroupObj[index].push({ name: index + 1, value: cItem?.value, fridgeName: cItem.name });
      } else {
        if (!tempMax) tempMax = cItem.value;
        if (!tempMin) tempMin = cItem.value;
        if (cItem?.value > tempMax) {
          tempMax = cItem.value;
        }
        if (cItem?.value < tempMin) {
          if (cItem.value) {
            tempMin = cItem.value;
          }
        }
        regroupObj[index] = [{ name: index + 1, value: cItem?.value, fridgeName: cItem.name }];
      }
    });
  });
  default_set_point = Math.abs(default_set_point);
  let numbers = [],
    markLine = [];
  if (tempMax && tempMin) {
    tempMax = Math.round(tempMax || 0);
    tempMin = Math.round(tempMin || 0);
    if (tempMin / tempMax > 0.5) {
      numbers = [Math.round(tempMax || 0), Math.round(tempMax / 2), 0];
    } else {
      numbers = [Math.round(tempMax || 0), Math.round((tempMax + tempMin) / 2), Math.round(tempMin || 0)];
    }
    if (tempMin < default_set_point) {
      numbers.push(default_set_point);
    }
    numbers.sort((a, b) => b - a);
  }
  for (let i = 1; i <= numbers.length; i++) {
    let yAxis = numbers[i - 1];
    let color = COLOR.mainSubLight,
      borderWidth = 1;
    if (yAxis == default_set_point) {
      color = COLOR.yellow;
      borderWidth = 2;
    }
    markLine.push({
      yAxis: yAxis,
      symbolSize: 0.1,
      lineStyle: { width: borderWidth, color: color },
      label: {
        show: true,
        color: COLOR.mainGrey,
        backgroundColor: COLOR.white,
        fontSize: "0.85rem",
        fontFamily: "Inter",
        formatter: yAxis == default_set_point ? "" : yAxis + " ℃"
      }
    });
  }
  for (let item in regroupObj) {
    seriesData.push({
      name: "Fridge at each store",
      type: "scatter",
      symbol: "rect",
      symbolSize: 16,
      smooth: true,
      zlevel: 3,
      animationDuration: 2000,
      data: regroupObj[item],
      label: {
        // 设置标签样式
        show: true, // 显示标签
        formatter: function (params) {
          return "F" + params.data.name;
        },
        color: COLOR.white,
        fontSize: "0.57rem",
        fontFamily: "Inter",
        position: "inside" // 标签位于顶部
      },
      itemStyle: {
        color: function (params) {
          return color[params.seriesIndex];
        },
        borderWidth: 1,
        borderColor: "transparent",
        borderRadius: 0 // 设置圆角大小
      },
      markLine: {
        symbol: "none",
        silent: true,
        lineStyle: { normal: { type: "solid" } },
        label: {
          position: "start",
          color: "white"
        },
        data: markLine
      }
    });
  }
  return {
    lineData: Array.from(new Set(numbers)),
    option: {
      tooltip: {
        zIndex: 2,
        trigger: "axis",
        confine: true,
        formatter: function (params) {
          let html = categoryX[params[0]?.["dataIndex"]] + "<br/>";
          params?.forEach(item => {
            if (item?.data?.value || item?.data?.value == 0) {
              html += `${item.marker}${item?.data?.fridgeName}：<span class="bold Inter">${ToRoundDecimalTemperature(item?.data?.value)}℃</span><br/>`;
            }
          });
          return html;
        }
      },
      legend: {
        show: false,
        itemWidth: 0,
        itemHeight: 0,
        data: [{ name: "Fridge at each store", icon: "rect", itemStyle: { color: COLOR.white } }],
        type: "plain",
        right: "5",
        formatter: function (params) {
          let str = "";
          for (let item in regroupObj) {
            str += "  ";
            str += `{fridge${parseInt(item) + 1}|${parseInt(item) + 1}}`;
          }
          let title = "Fridge at each store";
          return "{title|" + title + "}" + str;
        },
        textStyle: {
          ...WIDGET_CHART_COMMON_STYLE._insights_textStyle,
          rich: {
            title: {
              width: "auto", // 设置宽度自适应
              fontSize: "13px"
            },
            ...setAllFridgeStyleObj(regroupObj, color)
          }
        }
      },
      grid: {
        width: "auto",
        top: "8%",
        left: "5%",
        right: "0%",
        bottom: "0%",
        height: "92%",
        containLabel: true
      },
      title: {
        text: "",
        left: "center"
      },
      xAxis: {
        type: "category",
        splitLine: {
          show: false
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: true,
          interval: 0,
          textStyle: {
            ...WIDGET_CHART_COMMON_STYLE._insights_textStyle,
            color: COLOR.mainGrey
          },
          formatter: function (value) {
            if (width <= 6) {
              let str = "";
              let strArr = value.split(" ");
              for (let item in strArr) {
                str += strArr[item] + "\n\n";
              }
              return str;
            } else {
              return value;
            }
          }
        },
        data: categoryX
      },

      yAxis: {
        type: "value",
        splitLine: {
          show: false,
          lineStyle: {
            color: "transparent"
          }
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false,
          lineStyle: {
            width: 3
          }
        },
        axisLabel: {
          show: false,
          formatter: function (value) {
            return value;
          },
          rich: {
            val: {
              width: "auto" // 设置宽度自适应
            }
          },
          textStyle: {
            ...WIDGET_CHART_COMMON_STYLE._insights_textStyle,
            color: "transparent"
          }
        }
      },
      series: [...seriesData]
    }
  };
};
export const getDataFieldByVisualType = visualType => {
  switch (visualType) {
    case "HeatMapWidget":
      return "dataHeatMap";
    case "LineChartWidget":
      return {
        field: "dataLineChart",
        key: ["category", "data"]
      };
    case "BarChartWithMeterConsumptionCompareWidget":
      return {
        field: "dataBarChartCompare",
        key: ["category", "data"],
        series: {},
        xAxis: {
          axisLine: { show: false }
        }
      };
    case "BarChartWithBDSTWidget":
      return {
        field: "dataBarChartBdst",
        key: ["category", "data"],
        series: {},
        xAxis: {
          axisLine: { show: false }
        }
      };
    case "BarChartWithMeterUsageTypeWidget":
      return "dataBarChartUsage";
    case "BarChartWithMeterConsumptionPercentDifferWidget":
      return {
        field: "dataBarChartPercent",
        key: ["title"]
      };
    case "BarChartWaterBreakDownWidget":
      return {
        field: "dataBarChartWaterBreakDown",
        key: ["categoryX", "categoryY", "data"],
        itemColor: ["#6ED2C8", "#A4D7E8", "#78AEDC"]
      };
    case "BarChartEnergyBreakDownWidget":
      return {
        field: "dataBarChartEnergyBreakDown",
        key: ["categoryY", "data"],
        itemColor: ["#E97D7D", "#79CC79", "#C96BA1", "#F5B75F", "#6A94D4", "#BFE3F3", "#95C4E9", "#8EE5DE"]
      };
  }
};
export const BarChartWithMeterConsumptionCompareOption = (category, list, series, xAxis, widget) => {
  const _DATA = { ...list[0] } || [];
  if (!_DATA?.value?.length) {
    return {};
  }
  let MAX = 1,
    compareMAX = 0;
  _DATA.value = _DATA.value.map((item, index) => {
    MAX += item.value;
    if (item > compareMAX) {
      compareMAX = item.value;
    }
    let newItem = {
      value: item.value,
      name: item.name,
      color: WIDGET_CHART_COMMON_STYLE._barStyle.color[index]
    };
    return newItem;
  });
  return {
    tooltip: {
      formatter: function (params) {
        return `${params.name}<br/>${params.marker}<span class="bold">${UNIT_TO_COST_VALUE(widget.unit, params.value, true)}</span>`;
      }
    },
    xAxis: {
      type: "category",
      data: category,
      axisTick: { show: false },
      axisLine: {
        show: true,
        lineStyle: {
          color: COLOR.main
        }
      },
      axisLabel: {
        show: true,
        interval: 0,
        formatter: function (value) {
          return value;
        },
        textStyle: {
          ...WIDGET_CHART_COMMON_STYLE._insights_textStyle,
          color: COLOR.mainGrey
        }
      }
    },
    yAxis: {
      type: "value",
      scale: true, // 自适应设置
      min: 0,
      axisTick: { show: false },
      axisLabel: { show: false },
      splitLine: { show: false }
    },
    grid: {
      ...WIDGET_CHART_COMMON_STYLE._grid,
      width: "94%",
      left: "3%",
      height: "100%",
      bottom: "0"
    },
    series: [
      {
        data: _DATA.value,
        type: "bar",
        barWidth: "70%",
        ...series,
        itemStyle: {
          color: function (params) {
            return params.data.color;
          }
        },
        label: {
          show: true,
          position: "inside",
          fontsize: "0.92rem",
          fontFamily: "Inter",
          fontWeight: "normal",
          color: WIDGET_CHART_COMMON_STYLE._insights_textStyle.greyColor,
          textStyle: { align: "center", fontWeight: "normal", fontFamily: "Inter" },
          formatter: function (params) {
            if (params.value / compareMAX < 0.25 || params.value == 0) {
              return "";
            }
            return WIDGET_CHART_ACTION._unitFormatter(params, widget.source, widget.unit);
          },
          rich: { ...WIDGET_CHART_ACTION._barInnerRich }
        }
      }
    ]
  };
};
const getLineBarChartYAxis = widget => {
  return {
    type: "value",
    name: widget?.unit + UNIT_TO_SQUARE(widget?.unit),
    nameTextStyle: { color: COLOR.mainGrey, fontSize: "0.78rem", align: "center", fontFamily: "Inter" },
    splitLine: {
      show: false,
      lineStyle: {
        width: 1, // 刻度标线的宽度
        color: "white" // 设置分隔线的颜色
      }
    },
    splitArea: {
      show: false
    },
    axisLine: {
      show: true, // 显示 Y 轴的标线
      lineStyle: {
        ...WIDGET_CHART_STYLE._axisLineStyle,
        color: COLOR.mainGrey // 标线的颜色
      }
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: COLOR.mainGrey,
        fontSize: "0.8rem",
        fontFamily: "Inter"
      },
      formatter: function (value) {
        let _value = value;
        if (UNIT_RATE_OF_SCHOOL_STUDENT[widget?.unit]) {
          _value = value * UNIT_RATE_OF_SCHOOL_STUDENT[widget.unit];
        }
        return UNIT_COST_TO_YAXIS_VALUE(widget.unit, _value);
      }
    }
  };
};
const getLineBarChartLegend = legendData => {
  return {
    show: true,
    data: legendData,
    ...WIDGET_CHART_COMMON_STYLE["_legend"],
    // top: 10,
    padding: 0,
    formatter: function (name) {
      name = name?.split("_")?.[0];
      return "{a|" + name + "}";
    },
    textStyle: {
      ...WIDGET_CHART_COMMON_STYLE._insights_textStyle,
      rich: {
        a: {
          width: "auto" // 设置宽度自适应
        }
      }
    }
  };
};
const getLineBarChartGrid = (widget, params) => {
  return {
    ...WIDGET_CHART_COMMON_STYLE._gridIncludeLegend,
    left: "1%",
    right: "1%",
    bottom: widget.scrubber ? 25 : 10,
    splitLine: {
      show: true,
      lineStyle: {
        type: WIDGET_CHART_STYLE._axisLineStyle.type
      }
    },
    ...params
  };
};
export const BarAndLineChartWithComparsionWidgetOption = (category, data, compareData, referenceData, widget, avgName, t) => {
  let legend = [];
  let tempSeries = [],
    prevLineSeries = [],
    referenceLineSeries = [];
  let unit = WIDGET_UNIT_BY_SOURCE_TYPE[widget.unit];
  const color = WIDGET_CHART_COMMON_STYLE._barStyle.group_1;
  let legendName = "";
  if (widget?.params?.period == "WDP_CUSTOM_PERIOD" || widget?.autoCustom) {
    const { startTime, endTime } = widget;
    legendName = PAGE_PERIOD_LENGEND_TEXT("WDP_CUSTOM_PERIOD")(startTime, endTime);
  } else {
    legendName = PAGE_PERIOD_LENGEND_TEXT(widget.params.period, t);
  }
  data?.map((item, index) => {
    legend.push({ name: item?.name + "_" + index, icon: "rect", textStyle: { color: color[index] }, itemStyle: { color: color[index] } });
    tempSeries.push({
      type: "bar",
      data: item.value,
      name: item?.name + "_" + index,
      itemStyle: {
        color: function (params) {
          return color[index]; // 设置实心圆点的颜色为红色
        },
        borderRadius: [3, 3, 0, 0] // 上左、上右、下右、下左的圆角
      }
    });
  });
  if (!legend?.length) {
    return {};
  }
  if (widget.params.compare == "WDP_CUSTOM_PERIOD") {
    const { compareStartTime, compareEndTime } = widget;
    legendName = PAGE_PERIOD_LENGEND_TEXT("WDP_CUSTOM_PERIOD")(compareStartTime, compareEndTime);
  } else {
    legendName = PAGE_PERIOD_LENGEND_TEXT(widget.params.compare, t);
  }
  compareData?.map((item, index) => {
    legend.push({ name: legendName + "_" + index, icon: "rect", textStyle: { color: "#FFA500" }, itemStyle: { color: "#FFA500", borderColor: "transparent" } });
    prevLineSeries.push({
      name: legendName + "_" + index,
      ...WIDGET_CHART_COMMON_STYLE._seriesSymbolStyle,
      smooth: true,
      itemStyle: {
        normal: {
          color: "#FFA500", // 设置实心点的颜色
          borderColor: COLOR.main, // 设置边框颜色
          borderWidth: 2 // 设置边框宽度
        }
      },
      tooltip: {
        valueFormatter: function (value) {
          return value + unit;
        }
      },
      data: item.value
    });
  });
  if (referenceData) {
    legend.push({ name: referenceData.name, icon: "rect", textStyle: { color: COLOR.blue }, itemStyle: { color: COLOR.blue, borderColor: "transparent" } });
    referenceLineSeries.push({
      name: referenceData.name,
      // ...WIDGET_CHART_COMMON_STYLE._seriesSymbolStyle,
      type: "line",
      symbol: "none",
      cursor: "auto",
      yAxisIndex: 1,
      smooth: true,
      lineStyle: {
        width: 2,
        type: "solid", // 设置为虚线
        color: COLOR.blue
      },
      data: referenceData.data
    });
  }
  const yAxis = getLineBarChartYAxis(widget);
  return {
    legend: getLineBarChartLegend(legend),
    tooltip: {
      show: true,
      formatter: function (item) {
        let html = "",
          nameObj = {};
        if (widget.previousSingleParams) {
          let params = null;
          if (widget.previousSingleParams.pellet == "PELLET_HOUR") {
            params = moment(item.data.ts * 1000)
              .format("MM-DD h:a")
              .split(":");
            params = params[0] + "" + params[1] + " ";
          } else {
            params = moment(item.data.ts * 1000).format("MM-DD") + " ";
          }
          html += `${item.marker}${item?.data?.path || item.name}<br/>${params}<span class="bold">${UNIT_TO_COST_VALUE(widget?.unit, item.value, true)}</span>`;
        } else {
          html += `${item.marker}${item?.data?.path || item.name}<br/>${getTimeFormatByPelletOrViewby(
            widget,
            item?.data?.ts || item.axisValue,
            true
          )}<span class="bold">${UNIT_TO_COST_VALUE(widget?.unit, item.value, true)}</span>`;
        }

        return html;
      }
    },
    grid: getLineBarChartGrid(widget),
    dataZoom: [
      {
        show: widget.scrubber ? true : false,
        height: 20,
        right: "0.5%",
        xAxisIndex: [0],
        start: 0,
        end: 100,
        bottom: 0,
        handleIcon: "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
        handleSize: "110%",
        moveHandleSize: 7,
        filterMode: "filter",
        handleStyle: {
          color: COLOR.yellow,
          borderColor: "transparent",
          borderRadius: 0
        },
        textStyle: {
          color: COLOR.main,
          fontSize: 12,
          fontFamily: "Inter"
        },
        labelPreacision: "auto",
        labelFormatter: function (params, value) {
          return getTimeFormatByPelletOrViewby(widget, value);
        },
        showDetail: true,
        emphasis: {
          handleStyle: {
            borderColor: "transparent"
          },
          moveHandleStyle: {
            borderColor: "transparent"
          }
        },
        fillerColor: "rgba(255,165,0,.3)",
        borderColor: COLOR.yellow,
        borderRadius: 0,
        backgroundColor: "transparent",
        showDataShadow: false,
        brushSelect: false,
        borderJoin: "miter"
      },
      widget?.scrubber && {
        type: "inside",
        show: true,
        height: 15,
        start: 0,
        end: 100
      }
    ],
    xAxis: {
      type: "category",
      data: category,
      splitLine: {
        show: false
      },
      axisTick: {
        show: false // 显示 X 轴的刻度标线
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: COLOR.main
        }
      },
      axisLabel: {
        show: true,
        interval: widget.viewBy == "hour" && TimestampConvertPellet(widget.startTime, widget.endTime).pellet.indexOf("DAY") != -1 ? 0 : null,
        formatter: function (value, index) {
          let startTime = widget.startTime,
            endTime = widget.endTime;
          if (widget.previousSingleParams) {
            let params = null;
            if (widget.previousSingleParams.pellet == "PELLET_HOUR") {
              let params = moment(value * 1000)
                .format("h:a")
                .split(":");
              return params[0] + "" + params[1];
            } else {
              return moment(value * 1000).format("MM-DD") + " ";
            }
          } else {
            if (widget.viewBy == "hour") {
              if (TimestampConvertPellet(startTime, endTime).pellet.indexOf("DAY") != -1) {
                let result = getTimeFormatByPelletOrViewby(widget, value, false, "D/M");
                if (index % 24 == 0) {
                  return result;
                } else {
                  return "";
                }
                return getTimeFormatByPelletOrViewby(widget, value, false, "D/M");
              }
            }
            let result = getTimeFormatByPelletOrViewby(widget, value, false);
            return getTimeFormatByPelletOrViewby(widget, value, false);
          }
        },
        textStyle: {
          ...WIDGET_CHART_COMMON_STYLE._insights_textStyle,
          color: COLOR.mainGrey
        }
      }
    },
    yAxis: referenceData
      ? [
          yAxis,
          {
            ...yAxis,
            name: t("lbl.degree"),
            axisLabel: {
              show: true,
              width: 110,
              textStyle: {
                color: COLOR.mainGrey,
                fontSize: "0.8rem",
                fontFamily: "Inter"
              }
            }
          }
        ]
      : yAxis,
    series: [...tempSeries, ...prevLineSeries, ...referenceLineSeries]
  };
};
export const widgetChartResize = chartRef => {
  if (chartRef?.current) {
    chartRef.current.resize();
  }
};
export const ToDecimal = val => {
  if (val >= 10) {
    return Math.round(val);
  } else if (val >= 1 && val < 10) {
    return Math.round(val * 10) / 10;
  } else if (val >= 0 && val < 1) {
    return Math.round(val * 100) / 100;
  } else {
    return Math.round(val * 10) / 10;
  }
};
export const ToRoundDecimal = val => {
  if (val >= 10) {
    return Math.round(val);
  } else if (val >= 1 && val < 10) {
    return Math.round(val * 10) / 10;
  } else if (val >= 0 && val < 1) {
    return Math.round(val * 100) / 100;
  } else {
    return Math.round(val * 10) / 10;
  }
};

export const ToRoundDecimalTemperature = val => {
  if (val >= 1) {
    return Math.round(val * 10) / 10;
  } else if (val >= 0 && val < 1) {
    return Math.round(val * 100) / 100;
  } else {
    return Math.round(val * 10) / 10;
  }
};

export const getTimeFormatByPelletOrViewby = (widget, value, isTooltipDate, dateFormat) => {
  let viewBy,
    monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  if (widget?.params?.period) {
    if (widget?.params?.period == "WDP_CUSTOM_PERIOD") {
      const { startTime, endTime } = widget;
      viewBy = widget?.viewBy || getTogglByPellet(TimestampConvertPellet(startTime, endTime).pellet) || "hour";
    } else {
      viewBy = widget?.viewBy || getTogglByPellet(TimestampPeriodConversion(widget.params.period).pellet);
    }
  } else {
    let parameters = JSON.parse(widget.parameters);
    viewBy = widget?.viewBy || parameters.view_by;
  }

  if (viewBy == "hour") {
    let params = null;
    if (isTooltipDate) {
      if (dateFormat) {
        params = moment(value * 1000).format(dateFormat);
        return params;
      } else {
        params = moment(value * 1000)
          .format("YYYY/MM/DD h:a")
          .split(":");
        return params[0] + "" + params[1] + "<br/>";
      }
    } else {
      if (dateFormat) {
        params = moment(value * 1000).format(dateFormat);
        return params;
      } else {
        params = moment(value * 1000)
          .format("h:a")
          .split(":");
        return params[0] + "" + params[1];
      }
    }
  } else if (viewBy == "allDays" || viewBy == "weekends" || viewBy == "weekdays") {
    let params;
    if (dateFormat) {
      params = moment(value * 1000).format(dateFormat);
      return params;
    } else {
      params = moment(value * 1000)
        .format("h:a")
        .split(":");
      return params[0] + "" + params[1] + " ";
    }
  } else {
    if (viewBy == "day") {
      if (dateFormat) {
        return moment(value * 1000).format(dateFormat);
      } else {
        if (isTooltipDate) {
          return moment(value * 1000).format("D/M") + " ";
        }
        return moment(value * 1000).format("D/M");
      }
    } else {
      if (dateFormat) {
        return moment(value * 1000).format(dateFormat);
      } else {
        return monthsShort[moment(value * 1000).format("M") - 1];
      }
    }
  }
};

export const TimestampPreviousCustomedPeriodOrNext = (startTime, endTime, flag) => {
  let pellet;
  moment.utc();
  const startMoment = moment(startTime * 1000);
  const endMoment = moment(endTime * 1000);
  const duration = moment.duration(endMoment.diff(startMoment));
  let momentObj = null;
  if (duration.asDays() < 1) {
    // 将时间戳转换为moment对象，再减去一天
    if (flag == "sub") {
      momentObj = moment(startTime * 1000).subtract(1, "days");
    } else {
      momentObj = moment(endTime * 1000).add(1, "days");
    }
    startTime = momentObj.startOf("day").unix();
    endTime = momentObj.endOf("day").unix();
    pellet = "PELLET_HOUR";
  } else {
    if (flag == "sub") {
      momentObj = moment(startTime * 1000).subtract(1, "days");
      endTime = moment(endTime * 1000)
        .subtract(1, "days")
        .unix();
    } else {
      momentObj = moment(startTime * 1000).add(1, "days");
      endTime = moment(endTime * 1000)
        .add(1, "days")
        .endOf("day")
        .unix();
    }
    startTime = momentObj.startOf("day").unix();
    pellet = "PELLET_DAY";
  }
  return { startTime, endTime, pellet };
};

const getRollingMonthPeriodForPreviousOrNextAction = (values, flag, month) => {
  let startTime, endTime;
  if (values?.startTime) {
    let dayCount = getRollingMonthDayCount(month).dayCount;
    if (flag == "sub") {
      startTime = moment(values.startTime * 1000)
        .subtract(dayCount, "day")
        .unix();
      endTime = moment(values.endTime * 1000)
        .subtract(dayCount, "day")
        .unix();
    } else {
      startTime = moment(values.startTime * 1000)
        .add(dayCount, "day")
        .unix();
      endTime = moment(values.endTime * 1000)
        .add(dayCount, "day")
        .unix();
    }
  }
  return { startTime, endTime };
};
export const TimestampPreviousPeriodConversion = (values, flag) => {
  //for clicking previous/next btn
  let startTime, endTime, pellet;
  moment.utc();
  switch (values.period) {
    case "WDP_TODAY":
      if (values?.startTime) {
        let momentObj = null;
        if (flag == "sub") {
          momentObj = moment(values.startTime * 1000).subtract(1, "days");
        } else {
          momentObj = moment(values.startTime * 1000).add(1, "days");
        }
        startTime = momentObj.startOf("days").unix();
        endTime = momentObj.endOf("days").unix();
      }
      pellet = "PELLET_HOUR";
      break;
    case "WDP_YESTERDAY":
      if (values?.startTime) {
        let momentObj = null;
        if (flag == "sub") {
          momentObj = moment(values.startTime * 1000).subtract(1, "days");
        } else {
          momentObj = moment(values.startTime * 1000).add(1, "days");
        }
        startTime = momentObj.startOf("days").unix();
        endTime = momentObj.endOf("days").unix();
      }
      pellet = "PELLET_HOUR";
      break;
    case "WDP_THISWEEK":
      if (values?.startTime) {
        let momentObj = null;
        if (flag == "sub") {
          momentObj = moment(values.startTime * 1000).subtract(1, "week");
        } else {
          momentObj = moment(values.startTime * 1000).add(1, "week");
        }
        startTime = momentObj.startOf("week").unix();
        endTime = momentObj.endOf("week").unix();
      }
      pellet = "PELLET_DAY";
      break;
    case "WDP_LASTWEEK":
      if (values?.startTime) {
        let momentObj = null;
        if (flag == "sub") {
          momentObj = moment(values.startTime * 1000).subtract(1, "week");
        } else {
          momentObj = moment(values.startTime * 1000).add(1, "week");
        }
        startTime = momentObj.startOf("week").unix();
        endTime = momentObj.endOf("week").unix();
      }
      pellet = "PELLET_DAY";
      break;
    case "WDP_LAST2WEEK":
      if (values?.startTime) {
        let momentObj = null;
        if (flag == "sub") {
          momentObj = moment(values.startTime * 1000).subtract(2, "week");
          endTime = moment(values.startTime * 1000)
            .subtract(1, "week")
            .endOf("week")
            .unix();
        } else {
          momentObj = moment(values.startTime * 1000).add(2, "week");
          endTime = moment(values.endTime * 1000)
            .add(2, "week")
            .endOf("week")
            .unix();
        }
        startTime = momentObj.startOf("week").unix();
      }
      pellet = "PELLET_DAY";
      break;
    case "WDP_THISMONTH":
      if (values?.startTime) {
        let momentObj = null;
        if (flag == "sub") {
          momentObj = moment(values.startTime * 1000).subtract(1, "months");
        } else {
          momentObj = moment(values.endTime * 1000).add(1, "months");
        }
        startTime = momentObj.startOf("month").unix();
        endTime = momentObj.endOf("month").unix();
      }
      pellet = "PELLET_DAY";
      break;
    case "WDP_LASTMONTH":
      if (values?.startTime) {
        let momentObj = null;
        if (flag == "sub") {
          momentObj = moment(values.startTime * 1000).subtract(1, "months");
        } else {
          momentObj = moment(values.endTime * 1000).add(1, "months");
        }
        startTime = momentObj.startOf("month").unix();
        endTime = momentObj.endOf("month").unix();
      }
      pellet = "PELLET_DAY";
      break;
    case "WDP_THISQUARTER":
      if (values?.startTime) {
        let momentObj = null;
        if (flag == "sub") {
          momentObj = moment(values.startTime * 1000).subtract(1, "quarters");
        } else {
          momentObj = moment(values.endTime * 1000).add(1, "quarters");
        }
        startTime = momentObj.startOf("quarters").unix();
        endTime = momentObj.endOf("quarters").unix();
      }
      pellet = "PELLET_MONTH";
      break;
    case "WDP_LASTQUARTER":
      if (values?.startTime) {
        let momentObj = null;
        if (flag == "sub") {
          momentObj = moment(values.startTime * 1000).subtract(1, "quarters");
        } else {
          momentObj = moment(values.endTime * 1000).add(1, "quarters");
        }
        startTime = momentObj.startOf("quarters").unix();
        endTime = momentObj.endOf("quarters").unix();
      }
      pellet = "PELLET_MONTH";
      break;
    case "WDP_THISYEAR":
      if (values?.startTime) {
        let momentObj = null;
        if (flag == "sub") {
          momentObj = moment(values.startTime * 1000).subtract(1, "year");
        } else {
          momentObj = moment(values.endTime * 1000).add(1, "year");
        }
        startTime = momentObj.startOf("year").unix();
        endTime = momentObj.endOf("year").unix();
      }
      pellet = "PELLET_MONTH";
      break;
    case "WDP_LASTYEAR":
      if (values?.startTime) {
        let momentObj = null;
        if (flag == "sub") {
          momentObj = moment(values.startTime * 1000).subtract(1, "year");
        } else {
          momentObj = moment(values.endTime * 1000).add(1, "year");
        }
        startTime = momentObj.startOf("year").unix();
        endTime = momentObj.endOf("year").unix();
      }
      pellet = "PELLET_MONTH";
      break;
    case "WDP_MONTHTODATE":
      // 当前日期
      var today = moment();
      var todayDate = today.date(); // 当前月份的日期（例如25）
      if (values?.startTime) {
        let momentObj = null;
        if (flag == "sub") {
          momentObj = moment(values.startTime * 1000).subtract(1, "months");
        } else {
          momentObj = moment(values.endTime * 1000).add(1, "months");
        }
        startTime = momentObj.startOf("month").unix();
        endTime = momentObj.date(todayDate).endOf("day").unix();
      }
      pellet = "PELLET_DAY";
      break;
    case "WDP_QUARTERTODATE":
      today = moment();
      todayDate = today.date(); // 当前月份的日期（例如25）
      if (values?.startTime) {
        let momentObj = null;
        if (flag == "sub") {
          momentObj = moment(values.startTime * 1000).subtract(1, "quarters");
        } else {
          momentObj = moment(values.endTime * 1000).add(1, "quarters");
        }
        startTime = momentObj.startOf("quarters").unix();
        endTime = momentObj.date(todayDate).endOf("day").unix();
      }
      pellet = "PELLET_MONTH";
      break;
    case "WDP_YEARTODATE":
      today = moment();
      todayDate = today.date(); // 当前月份的日期（例如25）
      if (values?.startTime) {
        let momentObj = null;
        if (flag == "sub") {
          momentObj = moment(values.startTime * 1000).subtract(1, "year");
        } else {
          momentObj = moment(values.endTime * 1000).add(1, "year");
        }
        startTime = momentObj.startOf("year").unix();
        endTime = momentObj.date(todayDate).endOf("day").unix();
      }
      pellet = "PELLET_MONTH";
      break;
    case "WDP_PAST7DAYS":
      if (values?.startTime) {
        if (flag == "sub") {
          startTime = moment(values.startTime * 1000)
            .subtract(7, "days")
            .startOf("day")
            .unix();
          endTime = moment(values.startTime * 1000)
            .subtract(1, "days")
            .endOf("day")
            .unix();
        } else {
          startTime = moment(values.endTime * 1000)
            .add(1, "days")
            .startOf("day")
            .unix();
          endTime = moment(values.endTime * 1000)
            .add(7, "days")
            .endOf("day")
            .unix();
        }
      }
      pellet = "PELLET_DAY";
      break;
    case "WDP_PAST2WEEKS":
      if (values?.startTime) {
        if (flag == "sub") {
          startTime = moment(values.startTime * 1000)
            .subtract(14, "days")
            .startOf("day")
            .unix();
          endTime = moment(values.startTime * 1000)
            .subtract(1, "days")
            .endOf("day")
            .unix();
        } else {
          startTime = moment(values.endTime * 1000)
            .add(1, "days")
            .startOf("day")
            .unix();
          endTime = moment(values.endTime * 1000)
            .add(14, "days")
            .endOf("day")
            .unix();
        }
      }
      pellet = "PELLET_DAY";
      break;
    case "WDP_PASTMONTH":
      return { ...getRollingMonthPeriodForPreviousOrNextAction(values, flag, 1), pellet: "PELLET_DAY" };
      break;
    case "WDP_PAST3MONTH":
      return { ...getRollingMonthPeriodForPreviousOrNextAction(values, flag, 3), pellet: "PELLET_MONTH" };
      break;
    case "WDP_PAST6MONTH":
      return { ...getRollingMonthPeriodForPreviousOrNextAction(values, flag, 6), pellet: "PELLET_MONTH" };
      break;
    case "WDP_PAST12MONTH":
      return { ...getRollingMonthPeriodForPreviousOrNextAction(values, flag, 12), pellet: "PELLET_MONTH" };
      break;
  }
  return { startTime, endTime, pellet };
};
export const ViewOptionByPellet = pellet => {
  const AVG_OPTIONS = [
    { value: "allDays", pellet: "PELLET_AVG_DAY" },
    { value: "weekdays", pellet: "PELLET_AVG_WEEKDAY" },
    { value: "weekends", pellet: "PELLET_AVG_WEEKDEND" }
  ];
  switch (pellet) {
    case "PELLET_HOUR":
      return [{ value: "hour", pellet: "PELLET_HOUR" }];
      break;
    case "PELLET_DAY":
      return [{ value: "day", pellet: "PELLET_DAY" }, { value: "hour", pellet: "PELLET_HOUR" }, ...AVG_OPTIONS];
      break;
    case "PELLET_MONTH":
      return [{ value: "month", pellet: "PELLET_MONTH" }, { value: "day", pellet: "PELLET_DAY" }, ...AVG_OPTIONS];
      break;
    default:
      return [{ value: "month", pellet: "PELLET_MONTH" }, { value: "day", pellet: "PELLET_DAY" }, ...AVG_OPTIONS];
  }
};
export const TimestampConvertPellet = (startTime, endTime) => {
  let pellet;
  moment.utc();
  const startMoment = moment(startTime * 1000);
  const endMoment = moment(endTime * 1000);
  const duration = moment.duration(endMoment.diff(startMoment));
  if (duration.asDays() < 1) {
    pellet = "PELLET_HOUR";
  } else if (duration.asDays() < 7) {
    pellet = "PELLET_DAY";
  } else if (duration.asWeeks() < 1) {
    pellet = "PELLET_DAY";
  } else if (duration.asWeeks() < 4 || duration.asMonths() <= 1.02) {
    pellet = "PELLET_DAY";
  } else if (duration.asMonths() < 2) {
    pellet = "PELLET_DAY";
  } else if (duration.asMonths() < 13) {
    pellet = "PELLET_MONTH";
  }
  return { startTime, endTime, pellet };
};
export const TimestampPeriodConversion = (period, cutOfftime) => {
  let startTime, endTime, pellet;
  moment.utc();
  switch (period) {
    case "WDP_TODAY":
      startTime = moment().startOf("day").unix();
      endTime = moment().endOf("day").unix();
      pellet = "PELLET_HOUR";
      if (cutOfftime) {
        endTime = moment().minutes(0).seconds(0).milliseconds(0).unix();
      }
      break;
    case "WDP_YESTERDAY":
      startTime = moment().subtract(1, "days").startOf("day").unix();
      endTime = moment().subtract(1, "days").endOf("day").unix();
      pellet = "PELLET_HOUR";
      break;
    case "WDP_THISWEEK":
      startTime = moment().startOf("week").unix();
      endTime = moment().endOf("week").unix();
      pellet = "PELLET_DAY";
      break;
    case "WDP_LASTWEEK":
      startTime = moment().startOf("week").subtract(1, "week").unix();
      endTime = moment().endOf("week").subtract(1, "week").unix();
      pellet = "PELLET_DAY";
      break;
    case "WDP_LAST2WEEK":
      startTime = moment().subtract(2, "weeks").startOf("week").unix();
      endTime = moment().subtract(1, "weeks").endOf("week").unix();
      pellet = "PELLET_DAY";
      break;
    case "WDP_THISMONTH":
      startTime = moment().startOf("month").unix();
      endTime = moment().endOf("month").unix();
      pellet = "PELLET_DAY";
      break;
    case "WDP_LASTMONTH":
      startTime = moment().subtract(1, "months").startOf("month").unix();
      endTime = moment().subtract(1, "months").endOf("month").unix();
      pellet = "PELLET_DAY";
      break;
    case "WDP_THISQUARTER":
      startTime = moment().startOf("quarter").unix();
      endTime = moment().endOf("quarter").unix();
      pellet = "PELLET_MONTH";
      break;
    case "WDP_LASTQUARTER":
      startTime = moment().subtract(1, "quarters").startOf("quarter").unix();
      endTime = moment().subtract(1, "quarters").endOf("quarter").unix();
      pellet = "PELLET_MONTH";
      break;
    case "WDP_THISYEAR":
      startTime = moment().startOf("year").unix();
      endTime = moment().endOf("year").unix();
      pellet = "PELLET_MONTH";
      break;
    case "WDP_LASTYEAR":
      startTime = moment().startOf("year").subtract(1, "year").unix();
      endTime = moment().endOf("year").subtract(1, "year").unix();
      pellet = "PELLET_MONTH";
      break;
    case "WDP_MONTHTODATE":
      startTime = moment().startOf("month").unix();
      endTime = moment().unix();
      pellet = "PELLET_DAY";
      break;
    case "WDP_QUARTERTODATE":
      startTime = moment().startOf("quarters").unix();
      endTime = moment().unix();
      pellet = "PELLET_MONTH";
      break;
    case "WDP_YEARTODATE":
      startTime = moment().startOf("year").unix();
      endTime = moment().unix();
      pellet = "PELLET_MONTH";
      break;
    case "WDP_PAST7DAYS":
      startTime = moment().subtract(7, "days").startOf("day").unix();
      endTime = moment().subtract(1, "day").endOf("day").unix();
      pellet = "PELLET_DAY";
      break;
    case "WDP_PAST2WEEKS":
      startTime = moment().subtract(14, "days").startOf("day").unix();
      endTime = moment().subtract(1, "day").endOf("day").unix();
      pellet = "PELLET_DAY";
      break;
    case "WDP_PASTMONTH":
      return { ...getRollingMonthPeriod(1), pellet: "PELLET_DAY" };
      break;
    case "WDP_PAST3MONTH":
      return { ...getRollingMonthPeriod(3), pellet: "PELLET_MONTH" };
      break;
    case "WDP_PAST6MONTH":
      return { ...getRollingMonthPeriod(6), pellet: "PELLET_MONTH" };
      break;
    case "WDP_PAST12MONTH":
      return { ...getRollingMonthPeriod(12), pellet: "PELLET_MONTH" };
      break;
    default:
      pellet = "PELLET_MONTH";
      break;
  }
  return { startTime, endTime, pellet };
};

const getRollingMonthDayCount = month => {
  let startTimeMoment = moment().subtract(month, "months").startOf("day");
  let endTimeMoment = moment().subtract(1, "days").endOf("day");
  let dayCount = endTimeMoment.diff(startTimeMoment, "day") + 1; // +1 because endTime is 23:59:59, so 1 day missing
  return { startTimeMoment, endTimeMoment, dayCount };
};
const getRollingMonthPeriod = (month, isComparePeriod) => {
  let { startTimeMoment: startTime, endTimeMoment: endTime, dayCount } = getRollingMonthDayCount(month);
  if (isComparePeriod) {
    startTime = startTime.subtract(dayCount, "day").unix();
    endTime = endTime.subtract(dayCount, "day").unix();
  } else {
    startTime = startTime.unix();
    endTime = endTime.unix();
  }
  return { startTime, endTime };
};
export const TimestampPreviousConversion = (period, compare) => {
  let startTime, endTime, pellet;
  moment.utc();
  switch (period) {
    case "WDP_TODAY":
      if (compare == "WDP_PREVIOUSDAY") {
        startTime = moment().subtract(1, "days").startOf("day").unix();
        endTime = moment().subtract(1, "days").endOf("day").unix();
      } else if (compare == "WDP_SAMEDAY_LASTWEEK") {
        const today = moment();
        const sameDayLastWeek = today.subtract(7, "days");
        startTime = sameDayLastWeek.startOf("day").unix();
        endTime = sameDayLastWeek.endOf("day").unix();
      }
      // } else if (compare == "WDP_SAME_DAY_TWO_WEEKS_BEFORE") {
      //   const now = moment();
      //   const twoWeeksAgoSameDay = now.subtract(2, "weeks").startOf("day");
      //   startTime = twoWeeksAgoSameDay.startOf("day").unix();
      //   endTime = twoWeeksAgoSameDay.endOf("day").unix();
      // } else if (compare == "WDP_SAME_DAY_LAST_MONTH") {
      //   const currentDate = moment();
      //   let sameDayLastMonth = currentDate.subtract(1, "months").date(currentDate.date()); // 上个月的同一天
      //   startTime = sameDayLastMonth.startOf("day").unix();
      //   endTime = sameDayLastMonth.endOf("day").unix();
      // }
      pellet = "PELLET_HOUR";
      break;
    case "WDP_YESTERDAY":
      if (compare == "WDP_PREVIOUSDAY") {
        startTime = moment().subtract(2, "days").startOf("day").unix();
        endTime = moment().subtract(2, "days").endOf("day").unix();
      } else if (compare == "WDP_SAMEDAY_LASTWEEK") {
        const today = moment();
        const sameDayLastWeek = today.subtract(8, "days");
        startTime = sameDayLastWeek.startOf("day").unix();
        endTime = sameDayLastWeek.endOf("day").unix();
      }
      // } else if (compare == "WDP_SAME_DAY_TWO_WEEKS_BEFORE") {
      //   const now = moment().subtract(1, "days");
      //   const twoWeeksAgoSameDay = now.subtract(2, "weeks").startOf("day");
      //   startTime = twoWeeksAgoSameDay.startOf("day").unix();
      //   endTime = twoWeeksAgoSameDay.endOf("day").unix();
      // } else if (compare == "WDP_SAME_DAY_LAST_MONTH") {
      //   const currentDate = moment().subtract(1, "days");
      //   const sameDayLastMonth = currentDate.subtract(1, "months").date(currentDate.date()); // 上个月的同一天
      //   startTime = sameDayLastMonth.startOf("day").unix();
      //   endTime = sameDayLastMonth.endOf("day").unix();
      // }
      pellet = "PELLET_HOUR";
      break;
    case "WDP_THISWEEK":
      if (compare == "WDP_PREVIOUSWEEK") {
        startTime = moment().startOf("week").subtract(1, "week").unix();
        endTime = moment().endOf("week").subtract(1, "week").unix();
        // } else if (compare == "WDP_A_WEEK_AGO") {
        //   startTime = moment().startOf("week").subtract(2, "week").unix();
        //   endTime = moment().endOf("week").subtract(2, "week").unix();
        // } else if (compare == "WDP_TWO_WEEKS_AGO") {
        //   startTime = moment().startOf("week").subtract(3, "week").unix();
        //   endTime = moment().endOf("week").subtract(3, "week").unix();
      }
      pellet = "PELLET_DAY";
      break;
    case "WDP_LASTWEEK":
      if (compare == "WDP_PREVIOUSWEEK") {
        startTime = moment().subtract(2, "weeks").startOf("week").unix();
        endTime = moment().subtract(1, "weeks").endOf("week").unix();
        // } else if (compare == "WDP_A_WEEK_AGO") {
        //   startTime = moment().startOf("week").subtract(3, "week").unix();
        //   endTime = moment().endOf("week").subtract(3, "week").unix();
        // } else if (compare == "WDP_TWO_WEEKS_AGO") {
        //   startTime = moment().startOf("week").subtract(4, "week").unix();
        //   endTime = moment().endOf("week").subtract(4, "week").unix();
      }
      pellet = "PELLET_DAY";
      break;
    case "WDP_LAST2WEEK":
      if (compare == "WDP_PREVIOUS2WEEK") {
        startTime = moment().subtract(4, "weeks").startOf("week").unix();
        endTime = moment().subtract(3, "weeks").endOf("week").unix();
      }
      pellet = "PELLET_DAY";
      break;
    case "WDP_THISMONTH":
      if (compare === "WDP_PREVIOUSMONTH") {
        startTime = moment().subtract(1, "months").startOf("month").unix();
        endTime = moment().subtract(1, "months").endOf("month").unix();
      } else if (compare === "WDP_SAMEMONTH_PREVIOUSYEAR") {
        startTime = moment().subtract(1, "year").startOf("month").unix();
        endTime = moment().subtract(1, "year").endOf("month").unix();
      }
      pellet = "PELLET_DAY";
      break;
    case "WDP_LASTMONTH":
      if (compare === "WDP_PREVIOUSMONTH") {
        startTime = moment().subtract(2, "months").startOf("month").unix();
        endTime = moment().subtract(2, "months").endOf("month").unix();
      } else if (compare === "WDP_SAMEMONTH_PREVIOUSYEAR") {
        startTime = moment().subtract(1, "months").subtract(1, "year").startOf("month").unix();
        endTime = moment().subtract(1, "months").subtract(1, "year").endOf("month").unix();
      }
      pellet = "PELLET_DAY";
      break;
    case "WDP_THISQUARTER":
      if (compare === "WDP_PREVIOUSQUARTER") {
        startTime = moment().subtract(1, "quarters").startOf("quarter").unix();
        endTime = moment().subtract(1, "quarters").endOf("quarter").unix();
      } else if (compare === "WDP_SAMEQUARTER_PREVIOUSYEAR") {
        startTime = moment().subtract(1, "year").startOf("quarter").unix();
        endTime = moment().subtract(1, "year").endOf("quarter").unix();
      }
      pellet = "PELLET_MONTH";
      break;
    case "WDP_LASTQUARTER":
      if (compare === "WDP_PREVIOUSQUARTER") {
        startTime = moment().subtract(2, "quarters").startOf("quarter").unix();
        endTime = moment().subtract(2, "quarters").endOf("quarter").unix();
      } else if (compare === "WDP_SAMEQUARTER_PREVIOUSYEAR") {
        startTime = moment().subtract(1, "quarters").subtract(1, "year").startOf("quarter").unix();
        endTime = moment().subtract(1, "quarters").subtract(1, "year").endOf("quarter").unix();
      }
      pellet = "PELLET_MONTH";
      break;
    case "WDP_THISYEAR":
      startTime = moment().startOf("year").subtract(1, "year").unix();
      endTime = moment().endOf("year").subtract(1, "year").unix();
      pellet = "PELLET_MONTH";
      break;
    case "WDP_LASTYEAR":
      startTime = moment().startOf("year").subtract(2, "year").unix();
      endTime = moment().endOf("year").subtract(2, "year").unix();
      pellet = "PELLET_MONTH";
      break;
    case "WDP_MONTHTODATE":
      if (compare === "WDP_SAMEPERIOD_PREVIOUSMONTH") {
        startTime = moment().startOf("month").subtract(1, "month").unix();
        endTime = moment().endOf("day").subtract(1, "month").unix();
      }
      pellet = "PELLET_DAY";
      break;
    case "WDP_QUARTERTODATE":
      if (compare === "WDP_SAMEPERIOD_PREVIOUSQUARTER") {
        startTime = moment().startOf("quarter").subtract(1, "quarter").unix();
        endTime = moment().endOf("day").subtract(1, "quarter").unix();
      }
      pellet = "PELLET_MONTH";
      break;
    case "WDP_YEARTODATE":
      if (compare === "WDP_SAMEPERIOD_PREVIOUSYEAR") {
        startTime = moment().startOf("year").subtract(1, "year").unix();
        endTime = moment().endOf("day").subtract(1, "year").unix();
      }
      pellet = "PELLET_MONTH";
      break;
    case "WDP_PAST7DAYS":
      if (compare === "WDP_PREVIOUSPERIOD_PAST7DAYS") {
        startTime = moment().subtract(14, "days").startOf("day").unix();
        endTime = moment().subtract(8, "day").endOf("day").unix();
      }
      pellet = "PELLET_DAY";
      break;
    case "WDP_PAST2WEEKS":
      if (compare === "WDP_PREVIOUSPERIOD_PAST2WEEKS") {
        startTime = moment().subtract(28, "day").startOf("day").unix();
        endTime = moment().subtract(15, "day").endOf("day").unix();
      }
      pellet = "PELLET_DAY";
      break;
    case "WDP_PASTMONTH":
      if (compare === "WDP_PREVIOUSPERIOD_PASTMONTH") {
        return { ...getRollingMonthPeriod(1, true), pellet: "PELLET_DAY" };
      }
      break;
    case "WDP_PAST3MONTH":
      if (compare === "WDP_PREVIOUSPERIOD_PAST3MONTH") {
        return { ...getRollingMonthPeriod(3, true), pellet: "PELLET_MONTH" };
      }
      break;
    case "WDP_PAST6MONTH":
      if (compare === "WDP_PREVIOUSPERIOD_PAST6MONTH") {
        return { ...getRollingMonthPeriod(6, true), pellet: "PELLET_MONTH" };
      }
      break;
    case "WDP_PAST12MONTH":
      if (compare === "WDP_PREVIOUSPERIOD_PAST12MONTH") {
        return { ...getRollingMonthPeriod(12, true), pellet: "PELLET_MONTH" };
      }
      break;
    //TODOricky here and other PREVIOUSPERIOD, and other 2 method for rolling cases
  }
  return { startTime, endTime, pellet };
};
export const getTogglByPellet = (pellet, way) => {
  switch (pellet) {
    case "PELLET_HOUR":
      return "hour";
      break;
    case "PELLET_DAY":
      return "day";
      break;
    case "PELLET_MONTH":
      return "month";
      break;
    case "PELLET_AVG_DAY":
      return "allDays";
      break;
    case "PELLET_AVG_WEEKDAY":
      return "weekdays";
      break;
    case "PELLET_AVG_WEEKDEND":
      return "weekends";
      break;
  }
};

export const getTogglByStr = pelletStr => {
  switch (pelletStr) {
    case "hour":
      return "PELLET_HOUR";
      break;
    case "day":
      return "PELLET_DAY";
      break;
    case "month":
      return "PELLET_MONTH";
      break;
    case "allDays":
      return "PELLET_AVG_DAY";
      break;
    case "weekends":
      return "PELLET_AVG_WEEKDEND";
      break;
    case "weekdays":
      return "PELLET_AVG_WEEKDAY";
      break;
  }
};

export const disableDatesAfterTheCurrent = current => {
  // 禁用当前日期之后的日期
  return current && current > moment().endOf("day");
};
const combine = (resource_group, item, params, flag) => {
  let dataId = item?.datapoint?.dataId || null;
  let dataType = item?.datapoint?.dataType || null;
  if (dataType == "D_WATER_VIRTUAL_DATA_POINT" || dataType == "D_VIRTUAL_DATA_POINT") {
    let obj = { name: item.name, datapoint_ids: [{ id: item.id, data_id: dataId, metric: params.dataSource, is_virtual: true }] };
    if (flag) {
      return obj;
    }
    resource_group.push(obj);
  } else if (dataType == "D_IAQ") {
    let id_obj = { id: item?.id, parentId: item?.parentId };
    let obj = { name: item.name, datapoint_ids: [{ id: JSON.stringify(id_obj), data_id: dataId }] };
    if (flag) {
      return obj;
    }
    resource_group.push(obj);
  } else {
    let obj = { name: item.name, datapoint_ids: [{ id: item.id, data_id: dataId, metric: params.dataSource, is_virtual: false }] };
    if (flag) {
      return obj;
    }
    resource_group.push(obj);
  }
  return resource_group;
};
export const canHaveReferenceData = visualType => {
  return visualType === "LineChartWidget" || visualType === "BarChartWithBDSTWidget" || visualType === "BarAndLineChartWithComparisonWidget";
};
export const combineParametersBasedVisualType = (tableData, params, initialValues, visualType, toggleBy, viewBy, category, referenceId) => {
  let resource_group = [],
    dateFormat = "YYYY-MM-DD",
    parameters = {},
    default_metric = PAGE_LAYOUT_WIDGET_PARAMETER.UNIT_METRIC(initialValues.unit),
    default_reference_id = canHaveReferenceData(visualType) ? referenceId : null;
  switch (visualType) {
    case "BarChartWithMeterConsumptionCompareWidget":
    case "BarChartWithConsumptionRankingWidget":
    case "PieChartConsumptionWidget":
    case "BarChartWithBDSTWidget":
      if (visualType == "PieChartConsumptionWidget") {
        let _tableData = [];
        //If it is a selection, take the children directly, otherwise push them directly into the list
        if (tableData.length) {
          tableData?.forEach(item => {
            if (item?.children?.length) {
              item?.children?.map(cItem => {
                _tableData.push(cItem);
              });
            } else {
              _tableData.push(item);
            }
          });
        }
        tableData = _tableData;
      }
      tableData?.map((item, index) => {
        const { type } = item;
        if (
          visualType !== "BarChartWithMeterConsumptionPercentDifferWidget" &&
          visualType !== "BarChartWithConsumptionRankingWidget" &&
          visualType !== "PieChartConsumptionWidget"
        ) {
          if (toggleBy == "time") {
            let time = moment(item?.valuesOfHour?.[0]?.ts * 1000).format(dateFormat);
            if (type == "N_DATAPOINT") {
              resource_group = combine(resource_group, item, params);
            } else if (type == "N_SITE") {
              resource_group.push({ name: time, site_ids: [item.id] });
            } else {
              resource_group.push({ name: time, pov_ids: [item.id] });
            }
          } else {
            if (type == "N_DATAPOINT") {
              resource_group = combine(resource_group, item, params);
            } else if (type == "N_SITE") {
              resource_group.push({ name: item.name, site_ids: [item.id] });
            } else {
              resource_group.push({ name: item.name, pov_ids: [item.id] });
            }
          }
        } else {
          let temIndex = tableData.length; //category:多个点
          if (visualType != "PieChartConsumptionWidget") {
            temIndex = category ? tableData.length + 1 : 2; //category:多个点
            if (category) {
              params = { ...params, visualType: "BarChartWithConsumptionRankingWidget" };
            } else {
              params = { ...params, visualType: "BarChartWithMeterConsumptionPercentDifferWidget" };
            }
          }
          if (index < temIndex) {
            if (type == "N_DATAPOINT") {
              resource_group = combine(resource_group, item, params);
            } else if (type == "N_SITE") {
              resource_group.push({ name: item.name, site_ids: [item.id] });
            } else {
              resource_group.push({ name: item.name, pov_ids: [item.id] });
            }
          }
        }
      });
      parameters = {
        ...params,
        parameters: JSON.stringify({
          is_category_switch: category,
          view_by: viewBy,
          toggle_by: toggleBy,
          resource_group: resource_group,
          default_period: initialValues.period,
          default_metric
        })
      };
      break;
    case "BarChartWithMeterConsumptionPercentDifferWidget":
      tableData?.map((item, index) => {
        const { type } = item;
        if (
          visualType !== "BarChartWithMeterConsumptionPercentDifferWidget" &&
          visualType !== "BarChartWithConsumptionRankingWidget" &&
          visualType !== "PieChartConsumptionWidget"
        ) {
          if (toggleBy == "time") {
            let obj = {};
            let time = moment(item?.valuesOfHour?.[0]?.ts * 1000).format(dateFormat);
            if (type == "N_DATAPOINT") {
              resource_group = combine(resource_group, item, params);
            } else if (type == "N_SITE") {
              obj = { name: time, site_ids: [item.id] };
              if (tableData.length == 1) {
                obj = { ...obj, force_compared_period: initialValues.period };
                resource_group.push(obj);
                resource_group.push({ ...obj, force_compared_period: initialValues.compare });
              } else {
                resource_group.push(obj);
              }
            } else {
              obj = { name: time, pov_ids: [item.id] };
              if (tableData.length == 1) {
                obj = { ...obj, force_compared_period: initialValues.period };
                resource_group.push(obj);
                resource_group.push({ ...obj, force_compared_period: initialValues.compare });
              } else {
                resource_group.push(obj);
              }
            }
          } else {
            if (type == "N_DATAPOINT") {
              resource_group = combine(resource_group, item, params);
            } else if (type == "N_SITE") {
              obj = { name: item.name, site_ids: [item.id] };
              if (tableData.length == 1) {
                obj = { ...obj, force_compared_period: initialValues.period };
                resource_group.push(obj);
                resource_group.push({ ...obj, force_compared_period: initialValues.compare });
              } else {
                resource_group.push(obj);
              }
              // resource_group.push({ name: item.name, site_ids: [item.id], force_compared_period: initialValues.period });
              // resource_group.push({ name: item.name, site_ids: [item.id], force_compared_period: initialValues.compare });
            } else {
              obj = { name: item.name, pov_ids: [item.id] };
              if (tableData.length == 1) {
                obj = { ...obj, force_compared_period: initialValues.period };
                resource_group.push(obj);
                resource_group.push({ ...obj, force_compared_period: initialValues.compare });
              } else {
                resource_group.push(obj);
              }
              // resource_group.push({ name: item.name, pov_ids: [item.id], force_compared_period: initialValues.period });
              // resource_group.push({ name: item.name, pov_ids: [item.id], force_compared_period: initialValues.compare });
            }
          }
        } else {
          let temIndex = tableData.length; //category:多个点
          if (visualType != "PieChartConsumptionWidget") {
            temIndex = category ? tableData.length + 1 : 2; //category:多个点
            if (category) {
              params = { ...params, visualType: "BarChartWithConsumptionRankingWidget" };
            } else {
              params = { ...params, visualType: "BarChartWithMeterConsumptionPercentDifferWidget" };
            }
          }
          if (index < temIndex) {
            if (type == "N_DATAPOINT") {
              resource_group = combine(resource_group, item, params);
            } else if (type == "N_SITE") {
              obj = { name: item.name, site_ids: [item.id] };
              if (tableData.length == 1) {
                obj = { ...obj, force_compared_period: initialValues.period };
                resource_group.push(obj);
                resource_group.push({ ...obj, force_compared_period: initialValues.compare });
              } else {
                resource_group.push(obj);
              }
              // resource_group.push({ name: item.name, site_ids: [item.id], force_compared_period: initialValues.period });
              // resource_group.push({ name: item.name, site_ids: [item.id], force_compared_period: initialValues.compare });
            } else {
              obj = { name: item.name, pov_ids: [item.id] };
              if (tableData.length == 1) {
                obj = { ...obj, force_compared_period: initialValues.period };
                resource_group.push(obj);
                resource_group.push({ ...obj, force_compared_period: initialValues.compare });
              } else {
                resource_group.push(obj);
              }
              // resource_group.push({ name: item.name, pov_ids: [item.id], force_compared_period: initialValues.period });
              // resource_group.push({ name: item.name, pov_ids: [item.id], force_compared_period: initialValues.compare });
            }
          }
        }
      });
      parameters = {
        ...params,
        parameters: JSON.stringify({
          is_category_switch: category,
          view_by: viewBy,
          toggle_by: toggleBy,
          resource_group: resource_group,
          default_period: initialValues.period,
          default_metric
        })
      };
      break;
    case "BarAndLineChartWithComparisonWidget":
      var periodsList = [initialValues.period, initialValues.compare];
      tableData.forEach(item => {
        const { type, datapoint } = item;
        if (type == "N_DATAPOINT" || datapoint?.dataType == "D_TEMPERATURE") {
          resource_group = combine(resource_group, item, params);
        } else if (type == "N_SITE") {
          resource_group.push({ name: item.name, site_ids: [item.id] });
        } else {
          resource_group.push({ name: item.name, pov_ids: [item.id] });
        }
      });
      parameters = {
        ...params,
        parameters: JSON.stringify({
          view_by: viewBy,
          toggle_by: toggleBy,
          default_metric,
          default_reference_id,
          resource_comparison: { periods: periodsList, group: resource_group },
          default_period: initialValues.period
        })
      };
      break;
    case "BarChartWithMeterUsageTypeWidget":
    case "BarChartWaterBreakDownWidget":
    case "BarChartEnergyBreakDownWidget":
      var tempCategory = category;
      tableData.map((item, index) => {
        let obj = {};
        if (item?.type == "N_DATAPOINT") {
          const { name, datapoint_ids } = combine(resource_group, item, params, true);
          obj = { catename: name, datapoint_ids, subcates: [] };
        } else if (item?.type == "N_SITE") {
          obj = { catename: item.name, site_ids: [item.id], subcates: [] };
        } else {
          obj = { catename: item.name, pov_ids: [item.id], subcates: [] };
        }
        item.children?.map(cItem => {
          if (cItem?.type == "N_DATAPOINT") {
            const { name, datapoint_ids } = combine(resource_group, cItem, params, true);
            obj.subcates.push({ name, datapoint_ids });
          } else if (cItem?.type == "N_SITE") {
            obj.subcates.push({ name: cItem.name, site_ids: [cItem.id] });
          } else {
            obj.subcates.push({ name: cItem.name, pov_ids: [cItem.id] });
          }
        });
        resource_group.push(obj);
      });
      parameters = {
        ...params,
        parameters: JSON.stringify({
          view_by: viewBy,
          toggle_by: toggleBy,
          resource_category: resource_group,
          default_period: initialValues.period,
          is_category_switch: category,
          default_metric
        })
      };
      break;
    case "LineChartWidget":
      var obj = { name: "", group: [] };
      tableData.map((item, index) => {
        const { type, datapoint } = item;
        let group = {};
        if (type == "N_DATAPOINT" || datapoint?.dataType == "D_TEMPERATURE") {
          group = combine(resource_group, item, params, true);
        } else if (type == "N_SITE") {
          group = { name: item?.name, site_ids: [item?.id] };
        } else {
          group = { name: item?.name, pov_ids: [item?.id] };
        }
        obj = { ...obj, name: obj.name + item.name + (index == tableData.length - 1 ? "" : " vs "), group: [...obj.group, { ...group }] };
      });
      resource_group.push(obj);
      parameters = {
        ...params,
        parameters: JSON.stringify({
          view_by: viewBy,
          toggle_by: toggleBy,
          resource_multi_options: resource_group,
          default_period: initialValues.period,
          default_metric,
          default_reference_id
        })
      };
      break;
    case "HeatMapWidget":
      tableData.map((item, index) => {
        const { type } = item;
        if (type == "N_DATAPOINT") {
          resource_group = combine(resource_group, item, params);
        } else if (type == "N_SITE") {
          resource_group.push({ name: item.name, site_ids: [item.id] });
        } else {
          resource_group.push({ name: item.name, pov_ids: [item.id] });
        }
      });
      parameters = {
        ...params,
        parameters: JSON.stringify({ view_by: viewBy, toggle_by: toggleBy, resource_single_options: resource_group, default_period: initialValues.period, default_metric })
      };
      break;
    case "TemperatureWidget":
      tableData.forEach(item => {
        obj = { name: item.name, pov_ids: [item.id], datapoint_ids: [] };
        item?.children?.forEach(childItem => {
          const { name, datapoint_ids } = combine(resource_group, childItem, params, true);
          obj.datapoint_ids.push({ id: datapoint_ids[0]?.id, data_id: datapoint_ids[0]?.data_id, name: childItem?.name });
        });
        resource_group.push(obj);
      });
      parameters = {
        ...params,
        parameters: JSON.stringify({
          view_by: viewBy,
          toggle_by: toggleBy,
          resource_stores: resource_group,
          default_set_point: initialValues?.default_set_point || 4,
          default_period: initialValues.period,
          default_metric
        })
      };
      break;
  }
  return parameters;
};
